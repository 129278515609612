function initBurger() {
    let burgerBtn = document.querySelector('#burgerBtn')
    let burgerMenu = document.querySelector('.burger-menu')
    let navbar = document.querySelector('.navbar')
    let burgerActive = false

    function createTemplate(){
        let i = 0
        while (i <= 2){
            burgerBtn.appendChild(document.createElement("span"))
            i+=1
        }
    }

    document.addEventListener('click', () => {
        if (burgerBtn.classList.contains('active') && burgerActive == true) {
            burgerBtn.classList.remove('active')
            setTimeout(() => {burgerActive = false}, 100)
        }
        toggleMenu()
    })
    burgerBtn.addEventListener('click', () => {
        if (burgerActive == false) {
            burgerBtn.classList.add('active')
            burgerMenu.classList.add('first')
            setTimeout(() => {burgerActive = true}, 100)
        }
        toggleMenu()
        
    })

    function toggleMenu(){
        if (burgerBtn.classList.contains('active')) {
            burgerMenu.classList.add('is-active')
            navbar.classList.add('is-active')
        } else {
            burgerMenu.classList.remove('is-active')
            navbar.classList.remove('is-active')
        }
    }
    
    createTemplate()
} export { initBurger }