<template>
  <div  v-if="isLoaded.global" class="home" id="home">
    <HeaderHome id="top"/>
    <section id="about">
      <home-bio/>
    </section> 
    <home-current-project/>
    <home-services/>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderHome from '@/components/HeaderHome.vue'
import HomeBio from '@/components/HomeBio.vue'
import HomeCurrentProject from '@/components/HomeCurrentProject.vue'
import HomeServices from '@/components/HomeServices.vue'
import { mapState } from 'vuex'
//import Tab from '@/components/Tab.vue'
export default {
  name: 'Home',
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  components: {
    HeaderHome,
    HomeBio,
    HomeCurrentProject,
    HomeServices
    //Tab
  },
  data() {
    return {
      storageUrl: this.$store.state.storageUrl
    }
  },
  mounted () {
    
  },
  computed: {
    isLoaded() {
      return this.$store.state.isLoaded 
    },
    ...mapState(['global']),
  },
}
</script>

<style lang="scss">
.home, .home section{
  width: 100%;
}

#services, #contact{
  background-color: $grey-background;
  position: relative;
}

section{
  padding: 150px 0;
  background-repeat: no-repeat;
  background-size: cover;
  h2{
    color: $grey-text;
    font-family: $link-font;
    font-size: 32px;
    span{
      background-color: $main-color;
      color: white;
      padding: 1px 10px 4px;
      border-radius: 4px;
    }
  }
}

section#about{
  padding: 0;
}
</style>
