<template>
  <footer>
      <svg viewBox="0 0 1920 47" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <g id="Plan-de-travail1" transform="matrix(1,0,0,1,0.1,-33.7869)">
            <path d="M0,33.787C424.352,80.771 614.774,74.172 729.06,74.172C843.345,74.172 1217.24,37.46 1469.94,40C1722.64,42.54 1920,57.399 1920,57.399L1920,80L0,80C0,80 -0.225,64.263 0,33.787Z"/>
        </g>
      </svg>
      <div class="social-media">
        <div v-for="sm in global.socialMedia" :key="sm.id">
          <a :href="sm.value.url" target="_blank"><i :class="'g3i g3i-' + sm.value.name"></i></a>
        </div>
      </div>
      <img :src="storageUrl + logo" alt="Logo Color Record">
  </footer>
</template>

<script>
import { mapState } from "vuex"

export default {
    computed: {
        logo() {
            return this.$store.state.global.logo.path
        },
        ...mapState(['global'])

    },
    data() {
      return {
        storageUrl: this.$store.state.storageUrl
      }
    },    
}
</script>

<style lang="scss" scoped>
    @import './Footer.scss';
</style>