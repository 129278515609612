<template>
   <nav class="navbar" :class="[currentRoute !== 'Home' ? 'smaller' : '']">
      <div v-if="currentRoute === 'Home'" class="container">
        <div>
          <a class="logo nav-btn" href="#home">
            <img :src="storageUrl + header_img" alt="">
          </a>
        </div>
        <div class="navigation">
          <router-link to="/filmographie">Filmographie</router-link>
          <!-- <router-link to="/technique">Expertise Technique</router-link> -->
          <div id="burgerBtn"></div>
        </div>
      </div>
      <div v-else class="container">
          <div class="navigation only-back">
            <a class="back-btn" @click="backClick"><i class="g3i g3i-carret-left"></i><span class="ls after-icon">Retour</span></a>
            <div id="burgerBtn"></div>
          </div>
      </div>
      <div class="burger-menu">
        <router-link to="/filmographie">Filmographie</router-link>
        <!-- <router-link to="/technique">Expertise Technique</router-link> -->
        <!-- <div class="social-media">
            <div v-for="sm in global.socialMedia" :key="sm.id">
                <a :href="sm.value.url" target="_blank"><i :class="'g3i g3i-' + sm.value.name"></i></a>
            </div>
        </div> -->
      </div>
    </nav>
</template>

<script>
import {initBurger} from '@/plugins/burger.js'
import {mapState} from 'vuex'
export default {
    data() {
      return {
        storageUrl: this.$store.state.storageUrl,
        home: null,
      }
    },
    updated () {
        this.home = document.querySelector('#home');
    },
    mounted() {
        let goTop = document.querySelector('#go-to-top-btn')
        let html = document.querySelector('html')
        window.addEventListener('scroll',function(){
            let htmlTop = html.scrollTop
            let nav = document.querySelector('nav')
            if (this.home) {
                if(htmlTop >= 150){
                    nav.classList.add('smaller')
                } else {
                    nav.classList.remove('smaller')
                }
            }
            if(htmlTop >= 650){
                goTop.classList.remove('hidden')
            } else {
                goTop.classList.add('hidden')
            }
        });
        /* document.querySelectorAll('.nav-btn').forEach(link => {
            link.addEventListener('click', function(e){
                let pos = document.querySelector(link.hash).offsetTop
                e.preventDefault()
                let currentPos = document.querySelector('html').scrollTop
                window.scrollBy({ 
                    top: pos-currentPos, // could be negative value
                    left: 0, 
                    behavior: 'smooth' 
                });
            })
        }); */
        initBurger();
    },
    methods: {
        backClick(){
            const popup = document.querySelector('.secondary-layer_content--detail.active-mobile')
            if (popup) {
                popup.classList.remove('active-mobile')
            } else {
                this.$router.push('/')
            }
        }
    },
    computed: {
        header_img() {
            return this.$store.state.global.logoTextOnly.path
        },
        currentRoute() {
            return this.$route.name
        },
        ...mapState(['global'])
    },
}
</script>

<style lang="scss">
.navbar{
    position: fixed;
    width: 100%;
    padding: 34px 32px;
    z-index: 105;
    transition: .2s;
    .container{
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        transition: .2s;
    }
    & > div{
        height: 38px;
        .logo, .logo img{
            display: block;
            height: 26px;
            width: auto;
            opacity: 0;
            display: none;
            transition: .2s;
            path{
                fill: white !important;
            }
        }
        .navigation{
                display: flex;
                align-items: center;
                
            a, a:focus, a:visited{
                color: $grey-text;
                font-size: 1.2rem;
                text-transform: uppercase;
                margin-left: 30px;
                display: block;
                font-family: $link-font;
                letter-spacing: .5px;
                display: flex;
                transition: .3s;
                &:hover{
                    color: $main-color;
                }
                .g3i{
                    font-size: 18px;
                    line-height: 21px;
                    margin-right: 10px;
                }
                &:first-child{
                    margin-left: 0;
                }
            }
            &.only-back{
                width: 100%;
                justify-content: space-between;
            }
        }
    
    }
    &.smaller{
        padding: 8px 32px;
        background-color: $black-title;
        box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1);
        
        .container{
            justify-content: space-between;
        }
        .logo, .logo img{
            display: block;
            opacity: 1;
            height: 22px;
        }
        .navigation{
            a, a:focus, a:visited{
                color: $main-color;
            }
        }
        @include mq1024{
            padding: 8px 15px;
        }
    }
    @include mq1024{
        //background-color: $black-title;
        padding: 8px 15px;
        .container{
            justify-content: space-between;
        }
    }
    &.is-active{
        background-color: $black-title;
        .logo, .logo img{
            display: block;
            opacity: 1;
        }
    }
    .burger-menu{
        position: absolute;
        top: 100%;
        right: -300px;
        height: calc(100vh - 54px);
        background-color: $main-color;
        transition: .3s;
        display: none;
        padding: 45px 0;
        z-index: 105;
        a{
            color: white;
            display: block;
            padding: 15px 30px;
            min-width: 250px;
        }
        &.is-active{
            right: 0;
        }
        @include mq1024(){
            display: block;
        }
        .social-media{
            position: absolute;
            bottom: 30px;
            display: flex;
            a{
                min-width: 0;
                max-width: 48px;
            }
        }
    }
}
#burgerBtn{
    display: none !important;
    @include mq1024{
        display: block !important;
    }
}

.navigation:not(.only-back) a{
    display: block;
    @include mq1024{
        display: none !important;
    }
}

.back-btn{
    cursor: pointer;
    i.g3i, span{
        color: $main-color;
        filter: grayscale(0%);
        transition: .2s;
    }
    &:hover{
        i.g3i, span{
            color: $main-color;
            filter: grayscale(30%);       
            
        }
    }
}
</style>