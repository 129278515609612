const serverUrl = 'https://colorecord.com/api/index/'

const getSingleton =  serverUrl + 'api/singletons/get/'
const getCollection =  serverUrl + 'api/collections/get/'
const getAuth =  serverUrl + 'api/cockpit/authUser?token=f31f216d1d4b0266d0228358dd679c'
const getListCollections = serverUrl + 'api/collections/listCollections?token=f31f216d1d4b0266d0228358dd679c'

import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    storageUrl: serverUrl + 'storage/uploads',
    isLoaded: {
      global: false,
      prez: false,
      currentProject: false,
      clients: false,
      fictions: false,
      videos: false,
      services: false,
      matOp: false,
      matUsed: false

    },
    global: '',
    prez: '',
    currentProject: '',
    clients: '',
    collectionsList: '',
    fictions: null,
    videos: null,
    services: null,
    materiel: {
      op: '',
      used: ''
    },
    userToken: ''
  },
  mutations: {
    UPDATE_GLOBAL(state, gdata) {
      state.global = gdata
      state.isLoaded.global = true
    },
    UPDATE_PREZ(state, gdata) {
      state.prez = gdata
      state.isLoaded.prez = true
    },
    UPDATE_CURRENT_PROJECT(state, gdata) {
      state.currentProject = gdata
      state.isLoaded.currentProject = true
    },
    UPDATE_CLIENTS(state, gdata) {
      state.clients = gdata
      state.isLoaded.clients = true
    },
    UPDATE_SERVICES(state, gdata) {
      state.services = gdata
      state.isLoaded.services = true
    },
    UPDATE_FICTIONS(state, gdata) {
      state.fictions = gdata
      state.isLoaded.fictions = true
    },
    UPDATE_VIDEOS(state, gdata) {
      state.videos = gdata
      state.isLoaded.videos = true
    },
    UPDATE_COLLECTION_LIST(state, gdata) {
      state.collectionsList = gdata
    },
    UPDATE_MATOP(state, gdata) {
      state.materiel.op = gdata
      state.isLoaded.matop = true
    },
    UPDATE_MATUSED(state, gdata) {
      state.materiel.used = gdata
      state.isLoaded.matUsed = true
    },
    UPDATE_USERTOKEN(state, gdata){
      state.userToken = gdata
    }
  },
  actions: {
    getCollectionsList({commit}){
      axios.get(getListCollections).then((response) => {
        commit('UPDATE_COLLECTION_LIST', response.data)
      })
    },
    getGlobalData ({commit}) {
      axios.get(getSingleton + 'GlobalData').then((response) => {
        commit('UPDATE_GLOBAL', response.data)
      })
    },
    getPresentation ({commit}) {
      axios.get(getSingleton + 'bio').then((response) => {
        commit('UPDATE_PREZ', response.data)
      })
    },
    getCurrentProject ({commit}) {
      axios.get(getSingleton + 'currentProject').then((response) => {
        commit('UPDATE_CURRENT_PROJECT', response.data)
      })
    },
    getClients ({commit}) {
      axios.get(getCollection + 'client').then((response) => {
        commit('UPDATE_CLIENTS', response.data)
      })
    },
    getServices ({commit}) {
      axios.get(getSingleton + 'services').then((response) => {
        commit('UPDATE_SERVICES', response.data)
      })
    },
    getFictions ({commit}) {
      const params = {
        filter: {private: false},
        sort: {year: -1},
      };
      axios.post(getCollection + 'fiction', params).then((response) => {
        commit('UPDATE_FICTIONS', response.data)
      })
      /* fetch( getCollection + 'fiction', {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              filter: {private:false},
              sort: {year:-1},
              populate: 1, // resolve linked collection items
          })
      })
      .then(res=>res.json())
      .then(res => commit('UPDATE_FICTIONS', res)); */
    },
    getFictionsPrivate ({commit}, token) {
      const params = {
        sort: {year: -1},
      };
      axios.post(getCollection + 'fiction?token=' + token, params).then((response) => {
        commit('UPDATE_FICTIONS', response.data)
      })
    },
    getVideos ({commit}) {
      const params = {
        filter: {private: false},
        sort: {year: -1},
      };
      axios.post(getCollection + 'video', params).then((response) => {
        commit('UPDATE_VIDEOS', response.data)
      })
    },
    getVideosPrivate ({commit}, token) {
      const params = {
        sort: {year: -1},
      };
      axios.post(getCollection + 'video?token=' + token, params).then((response) => {
        commit('UPDATE_VIDEOS', response.data)
      })
    },
    getMaterielOp ({commit}) {
      const params = {
        sort: {name: 1},
      };
      axios.post(getCollection + 'materiel_op', params).then((response) => {
        commit('UPDATE_MATOP', response.data)
      })
    },
    getMaterielUsed ({commit}) {
      const params = {
        sort: {name: 1},
      };
      axios.post(getCollection + 'materiel_used', params).then((response) => {
        commit('UPDATE_MATUSED', response.data)
      })
    },
    getUserToken({commit}, data){
      axios.post(getAuth, data).then((response) => {
        commit('UPDATE_USERTOKEN', response.data.api_key)
      })
    }
  },
  modules: {
  }
})
