<template>
<section v-if="isLoaded.services" id="services">
    <svg viewBox="0 0 1920 47" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <g id="Plan-de-travail1" transform="matrix(1,0,0,1,0.1,-33.7869)">
            <path d="M0,33.787C424.352,80.771 614.774,74.172 729.06,74.172C843.345,74.172 1217.24,37.46 1469.94,40C1722.64,42.54 1920,57.399 1920,57.399L1920,80L0,80C0,80 -0.225,64.263 0,33.787Z"/>
        </g>
    </svg>
    
    <!-- <div class="wrap1140">
        <div class="home-services">
            <div class="wrap1140">
                <div class="content">
                    
                    <div class="clients-slider">
                        <swiper ref="mySwiper" :options="swiperOptions">
                            <swiper-slide v-for="client in clients.entries" :key="client.id">
                                <img :src="storageUrl + client.logo.path" alt="">
                            </swiper-slide>
                            
                        </swiper>
                        <div class="swiper-button-prev" slot="button-prev"></div>
                        <div class="swiper-button-next" slot="button-next"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class='service-title'>
        <div class="wrap1140">
            <h2>Ils nous font confiance!</h2>
        </div>
    </div> -->
    <div class="services-content">
        
        <svg viewBox="0 0 1920 47" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
            <g id="Plan-de-travail1" transform="matrix(1,0,0,1,0.1,-33.7869)">
                <path d="M0,33.787C424.352,80.771 614.774,74.172 729.06,74.172C843.345,74.172 1217.24,37.46 1469.94,40C1722.64,42.54 1920,57.399 1920,57.399L1920,80L0,80C0,80 -0.225,64.263 0,33.787Z"/>
            </g>
        </svg>
        
        <div class=" wrap1140">
            <div v-for="service in services.servicesBox" :key="service.id">
                <h3>{{ service.value.Titre }}</h3>
                <div v-html="service.value['Détail']"></div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
      return {
        storageUrl: this.$store.state.storageUrl,
        swiperOptions: {
            loop: true,
            slidesPerView: 3,
            //slidesPerGroup: 3,
            spaceBetween: 60,
            //slideClass: 'custom-slide-class',
            autoplay: {
                delay: 2500,
                disableOnInteraction: false
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {
                1024: {
                slidesPerView: 4,
                spaceBetween: 40
                },
                768: {
                slidesPerView: 3,
                spaceBetween: 30
                },
                640: {
                slidesPerView: 2,
                spaceBetween: 20
                },
                320: {
                slidesPerView: 1,
                spaceBetween: 10
                }
            }
          // Some Swiper option/callback...
        }
      }
    },
    beforeMount () {
        this.getClients();
        this.getServices();
    },

    methods: {
        ...mapActions(['getClients', 'getServices']),
    },
    computed: {
        ...mapState(['clients', 'global', 'isLoaded', "services"]),
    }
}
</script>

<style lang='scss'>
#services{
    padding-top: 0;
    padding-bottom: 0;
    background-color: $black-background;
    position: relative;
    & > svg{
        position: absolute;
        bottom: calc(100% - 2px);
        z-index: 2;
        filter: drop-shadow( 0 -12px 6px rgba($black, .3));
    }
    & > svg, & > svg path{
        width: 100% !important;
        fill: $black-background;
    }
}
.service-title{
    text-align: center;
    padding: 0px 15px 90px;
    background: $black-background;
    h2{
        color: $black-background;
        font-size: 32px;
        font-family: $sub-title-font;
        letter-spacing: 2px;
        border-top: 1px solid rgba($black-background, .5);
        width: 50%;
        padding-top: 30px;
        margin: 0 auto;
    }
}
.services-content{
    background-color: $black-background;
    padding: 120px 0px;
    position: relative;
    @include mq768{
        padding: 90px 0px 0px;
    }
    svg{
        display: none;
        position: absolute;
        height: auto;
        width: 100%;
        transform: scaleY(.5);
        bottom: calc(100% - 106px);
        z-index: 2;
        filter: drop-shadow( 0 -12px 6px rgba($black, .3));
    }
    svg, path{
        width: 100% !important;
        fill: $black-background;
    }
    & > div{
        display: flex;
        flex-wrap: wrap;
        & > div{
            width: 50%;
            padding: 30px 60px;
            h3{
                color: $main-color;
                margin-bottom: 5px;
            }
            @include mq768{
                width: 100%;
                padding: 30px;
            }
        }
    }
}
.clients-slider{
    position: relative;
    padding: 45px 10px;
    margin-bottom: 0;
    .swiper-button-prev{
        left: auto;
        right: 100%;
    }
    .swiper-button-next{
        right: auto;
        left: 100%;  
    }
    .swiper-button-prev, .swiper-button-next{
        color: $black-background;
        &::after{
            font-size: 32px;
        }
        @include mq1280{
            display: none;
        }
    }
}
.swiper-slide{
    text-align: center;
    img{
        max-height: 120px;
        max-width: 300px;
        width: auto;
        margin: 0 auto;
        text-align: center;
    }
}
</style>