<template>
  <div id="app">
    <Navbar v-if="isLoaded.global"/>
    <transition name="slide-fade">
      <router-view/>
    </transition>
    
    <div class="hidden" id="go-to-top-btn">
      <a href="#top" class="nav-btn"><i class="g3i g3i-carret-top"></i></a>
    </div>
    <Footer v-if="isLoaded.global"/>
    <div id="fb-root" class="hidden"></div>
    <div class="fb-customerchat"
        attribution=setup_tool
        page_id="1121029164642170"
        theme_color="#0A7CFF"
        logged_in_greeting="Un projet ? Contactez-nous !"
        logged_out_greeting="Un projet ? Contactez-nous !">
    </div>
    <div class="contact-data hidden">
      <a :href="'tel:' + global.phone"><i class="g3i g3i-phone"></i><span>{{ global.phone }}</span></a>
      <a :href="'mailto:' + global.email"><i class="g3i g3i-mail"></i><span>{{ global.email }}</span></a>
    </div>

  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import { mapActions} from 'vuex'
export default {
  components: {
    Navbar,
    Footer
  },
  mounted () {
    this.getGlobalData();
    window.fbAsyncInit = function() {
      window.FB.init({
          xfbml            : true,
          version          : 'v8.0'
        });
      };

      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/fr_FR/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
      let fb = document.querySelector('#fb-root')
      let ctData = document.querySelector('.contact-data')
      let html = document.querySelector('html')
      window.addEventListener('scroll',function(){
            let htmlTop = html.scrollTop
            if(htmlTop >= 450){
                ctData.classList.remove('hidden')
                fb.classList.remove('hidden')

            } else {
                ctData.classList.add('hidden')
                fb.classList.add('hidden')

            }
        });
  },
  methods: {
    ...mapActions(['getGlobalData']),
  },
  computed: {
    isLoaded() {
      return this.$store.state.isLoaded 
    },
    global() {
      return this.$store.state.global
    }
  },
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  max-width: 1981px;
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  #go-to-top-btn{
    position: fixed;
    z-index: 100;
    bottom: 95px;
    right: 24px;
    display: none;
    //background-color: white;
    border: 2px solid $black-content;
    border-radius: 50%;
    transition: all 0.2s, right 0.4s;
    opacity: .6;
    &.hidden{
      right: -65px;
    }
    a{
      padding: 16px;
      display: block;
    }
    .g3i{
      color: $black-content;
      transition: 0.2s;
    }
    &:hover{
      background-color: #0A7CFF;
      border: 2px solid #0A7CFF;
      opacity: 1;
      .g3i{
        color: white;
      }
    }
  }
  #fb-root{
    opacity: 0;
    transition: .5s;
    &:not(.hidden){
      opacity: 1;
    }
  }
  .contact-data{
    position: fixed;
    bottom: 22px;
    right: 90px;
    z-index: 200;
    box-sizing: border-box;
    opacity: 0;
    transition: .5s;
    &:not(.hidden){
      opacity: 1;
    }
    a{
      color: white;
      background-color: #0A7CFF;
      padding: 16px;
      margin: 0 5px;
      border-radius: 30px;
      width: auto;
      max-width: 60px;
      height: 60px;
      overflow: hidden;
      display: inline-block;
      transition: .3s;
      i.g3i{
        font-size: 28px;
      }
      span{
        width: auto;
        transform: scale(0);
        opacity: 0;
        margin-left: 15px;
        color: #0A7CFF;
        transition: all .3s .2s, color .1s;
      }
      @media (min-width: 1024px) {
        &:hover{
          max-width:  300px;
          padding: 16px 32px;
          span{
            color: white;
            transform: scale(1);
            opacity: 1;
          }
        }
      }
    }
  }
  .slide-fade-enter-active {
    transition: all .6s ease;
    animation: fadeIn .6s ease-in;
    animation-delay: .6s;
  }
  .slide-fade-leave-active {
    transition: all .6s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    //transform: translateX(20%);
    opacity: 0;
  }
  @keyframes fadeIn {
    from{
      opacity: 0;
      //transform: translateX(20%);
    } to {
      opacity: 1;
      //transform: translateX(0);
    }
  }
}
</style>
