<template>
<div v-if="isLoaded.global" class="sub-page" id="top">
  <header :style="{backgroundImage: 'url(' + storageUrl + global.backgroundFilmographie.path + ')'}">
      <h1>{{  global.titleFilmographie }}</h1>
      <p>{{  global.subTitleFilmographie }}</p>
      <svg width="100%" height="auto" viewBox="0 0 1920 47" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <g id="Plan-de-travail1" transform="matrix(1,0,0,1,0.1,-33.7869)">
            <path d="M0,33.787C424.352,80.771 614.774,74.172 729.06,74.172C843.345,74.172 1217.24,37.46 1469.94,40C1722.64,42.54 1920,57.399 1920,57.399L1920,80L0,80C0,80 -0.225,64.263 0,33.787Z" style="fill:rgb(46,46,46);"/>
        </g>
    </svg>
  </header>
  <div id="filmographie" class="secondary-layer wrap1140">
    <div class="filmo-btn">
      <a 
        :class="[currentListActive === 'fictions' ? 'active' : '']"
        @click="currentList = fictions; currentDetail = 0; active = 0; currentListActive = 'fictions'" class="btn">
        Fictions
      </a>
      <a
        :class="[currentListActive === 'videos' ? 'active' : '']"
        @click="currentList = videos; currentDetail = 0; active = 0; currentListActive = 'videos'" class="btn">
        TV
      </a>
      <a @click="needLogin = !needLogin" class="btn private">{{ needLogin ? '-' : '+' }}</a>
    </div>

    <div v-if="currentList != undefined" class="secondary-layer_content">
      <div class="secondary-layer_content--table">
        <div v-show="needLogin" class="connect-form">
        <input v-model="login" type="text" name="login" placeholder="login">
        <input v-model="password" type="password" name="password" placeholder="password">
        <a class="btn" @click="sendLogin" >login</a>
      </div>
        <div class="secondary-layer_content--row" v-for="(el, i) in currentList.entries" :key="el.id" @click="active = i; loadDetail(i, $event)" :class="{ active: active == i }">
          <h3>{{ el.title }}</h3>
          <p><span>{{ el.year }} - </span> {{ el.genre }}</p>
          <div class="row-icon">
            <i class="g3i g3i-carret-right"></i>
          </div>
        </div>
      </div>
      <div class="secondary-layer_content--detail">
        <div class="secondary-layer_content--detail__content active">
          <h3>{{ currentList.entries[currentDetail].title }}</h3>
          <div class="details">
            <p><span>Année: </span>{{ currentList.entries[currentDetail].year }}</p>
            <p><span>Réalisation: </span>{{ currentList.entries[currentDetail].realisateur }}</p>            
            <p><span>Poste occupé: </span>{{ currentList.entries[currentDetail].posteOccupe }}</p>
            <p><span>Camera: </span>{{ currentList.entries[currentDetail].camera }}</p>
          </div>
          
          <div class="resume" v-html="currentList.entries[currentDetail].resume"></div>
          <div v-if="currentList.entries[currentDetail].videoPlatform === 'youtube'" class="video">
            <iframe width="100%" height="315" :src="'https://www.youtube.com/embed/' + currentList.entries[currentDetail].videoLink" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div v-else-if="currentList.entries[currentDetail].videoPlatform === 'vimeo'" class="video">
            <iframe :src="'https://player.vimeo.com/video/' + currentList.entries[currentDetail].videoLink" width="100%" height="315" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  
  </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      storageUrl: this.$store.state.storageUrl,
      currentList: this.fictions,
      currentListActive: 'fictions',
      currentDetail: 0,
      active:0,
      login: '',
      password: '',
      needLogin: false,
    }
  },
  beforeMount () {
    this.getFictions();
    this.getVideos();
  },
  mounted () {
    
  },
  methods: {
    ...mapActions(['getFictions', 'getVideos', 'getFictionsPrivate', 'getVideosPrivate', 'getUserToken']),
    loadDetail(index, event){
      var pBox = document.querySelector('.secondary-layer_content--detail')
      var cBox = document.querySelector('.secondary-layer_content--detail__content')  
      this.currentDetail = index

      function animClass(el, myClass) {
          if(el.classList.contains(myClass)){
            el.classList.remove(myClass)
          } else {
            el.classList.add(myClass)
          }
      }
      if (window.screen.width >= 1024) {
        animClass(cBox, 'active')
        this.positionBox(event)
        setTimeout(function () {animClass(cBox, 'active')}, 500)
      } else {
        animClass(pBox, 'active-mobile')
      }
    },
    positionBox(event){
      var btnClicked = event.target;
      var btnCOffsetTop = btnClicked.offsetTop;
      var btnCH = btnClicked.clientHeight
      var box = '';
      var boxH = '';
      var parentBox = document.querySelector('.secondary-layer_content--detail')
      var parentBoxH = document.querySelector('.secondary-layer_content--table').clientHeight
      setTimeout(function (){
        box = document.querySelector('.secondary-layer_content--detail__content')
        boxH = box.clientHeight

        var initialBoxPosBottom = (parentBox.offsetTop + box.offsetTop) + boxH
        if(btnCOffsetTop + btnCH > initialBoxPosBottom){
          let offset = btnCOffsetTop + btnCH - initialBoxPosBottom
          box.animate([
            {top: box.offsetTop + offset + 10 + 'px'}
          ],{
            delay: 0,
            duration: 400,
            fill: 'forwards',
            easing: "ease-out"
          })
        }
        if(btnCOffsetTop < parentBox.offsetTop + box.offsetTop){
          let offset = btnCOffsetTop - parentBox.offsetTop - 70 ;
          box.animate([
            {top: offset + 'px'}
          ],{
            delay: 0,
            duration: 400,
            fill: 'forwards',
            easing: "ease-out"
          })
        }
        else if(initialBoxPosBottom > parentBox.offsetTop + parentBoxH){
          let offset = btnCOffsetTop + btnCH - parentBox.offsetTop - boxH + 10
          if(offset < 0 -70){
            offset = 0 -70
          }
          box.animate([
            {top: offset + 'px'}
          ],{
            delay: 0,
            duration: 400,
            fill: 'forwards',
            easing: "ease-out"
          })
        }
      }, 100);
            
    },
    sendLogin(){
      const loginData = {
        user: this.login,
        password: this.password
      }
      this.getUserToken(loginData)
      this.password = ''
      this.login = ''
    },
  },
  computed: {
    ...mapState(['fictions', 'videos', 'global', 'isLoaded', 'userToken'])
  },
  watch: {
    fictions(newValue, oldValue) {
      if (oldValue === null & newValue !== null | newValue !== null) {
        this.currentList = this.fictions
      }
    },
    userToken(newValue, oldValue){
      if (oldValue === '' & newValue != oldValue){
        this.getFictionsPrivate(this.userToken)
        this.getVideosPrivate(this.userToken)
      }
    }

  },
}
</script>


<style lang="scss" scoped>
    @import './Secondary.scss';
</style>
