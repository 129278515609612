<template>
    <div v-if="isLoaded.prez === true" class="home-presentation">
        <div class="prez-image">
            <img :src="storageUrl + prez.photo.path" alt="Clément Grosdenier">
        </div>
        <div class="prez-content">
            <div v-html="prez.contenu"></div>
            <div v-if="prez.yt" class="video" id="video">
                <h3>Showreel</h3>
                <iframe id="ytVid" width="100%" height="360" :src="'https://www.youtube.com/embed/' + prez.yt" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <svg
                xmlns:dc="http://purl.org/dc/elements/1.1/"
                xmlns:cc="http://creativecommons.org/ns#"
                xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                xmlns:svg="http://www.w3.org/2000/svg"
                xmlns="http://www.w3.org/2000/svg"
                id="svg8"
                version="1.1"
                viewBox="0 0 11.991206 171.36453">
                <g
                    transform="translate(-123.45656,-54.330254)"
                    id="layer1">
                    <path
                    id="path815"
                    d="M 135.31547,54.339286 V 225.5625 h -11.71726 z"
                    style="fill:#2E2E2E; stroke-width:0.26458332px;" />
                </g>
                </svg>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    data() {
      return {
        storageUrl: this.$store.state.storageUrl
      }
    },
    beforeMount () {
        this.getPresentation();
    },
    mounted () {
    },
    methods: {
        ...mapActions(['getPresentation']),
    },
    computed: {
        ...mapState(['prez', 'isLoaded']),
    }
}
</script>

<style lang="scss">
.home-presentation{
    display: flex;
    @include mq1280{
        flex-wrap: wrap;
    }
    & > div{
        &.prez-image{
            display: flex;
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            background-color: white;
            width: 45%;
            background-color: #030303;
            img{
                max-width: 100%;
                object-fit: cover;
            }
        }
        &.prez-content{
            padding: 60px;
            position: relative;
            width: 60%;
            box-sizing: border-box;
            h3{
                margin-bottom: 15px;
                color: $main-color;
            }
            svg{
                position: absolute;
                top: 0;
                height: 100%;
                right: calc(100% - 1px);
            }
        }
        @include mq1680{
             &.prez-image img{
                 max-width: 100%;
             }

        }
        @include mq1440{
             &.prez-image img{
                 max-width: 100%;
             }

        }
        @include mq1280{
            width: 100% !important;
            &.prez-image img{
                 max-width: 40%;
             }
        }
        @include mq768{
            &.prez-image img{
                 max-width: 50%;
             }
             &.prez-content{
                 padding: 30px 15px;
             }
        }
    }
    h2{
        margin-bottom: 32px;
    }
    .prez-text{
        display: flex;
        text-align: left;
        flex-wrap: wrap;
        & > div{
            width: calc(100% - 250px);
            padding: 0 45px;
            justify-content: center;
            align-items: center;
        }
    }
    .video{
        position: relative;
        background-color: $black-title;
        padding: 30px 30px;
        box-sizing: border-box;
        height: auto;
        display: inline-block;
        border-radius: 5px;
        transform: translateX(-30px);
        h3{
            margin-bottom: 15px;
        }
        @include mq1280{
            transform: translateX(0px);
        }
        @include mq768{
            padding: 15px 10px;
        }
    }

    
    
}

#ytVid{
    .ytp-chrome-top, .ytp-chrome-bottom, .ytp-chrome-top.ytp-show-cards-title{
        display: none !important;
        opacity: 0 !important;
        color: transparent !important;
    }
}
.ytd-search .ytd-section-list-renderer:nth-child(1) { display:inherit !important;}
.ytd-section-list-renderer:nth-child(1), ytd-browse[page-subtype=home] .ytd-grid-renderer { display:none !important;}
.ytp-endscreen-content{
    display:none !important;
    left: 100%;
}
</style>