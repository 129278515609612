<template>
  <div>
    <header>
      <div class="video">
        <video preload="auto" autoplay muted loop>
          <source :src="storageUrl + video" type="video/mp4">
        </video>
      </div>
      <div class="header-content show">
        <div class="container">
            <div class="header-text-content">
              <img :src="storageUrl + logo" alt="Logo Color Record">
              
              <div>
                <a class="link-more nav-btn" href="#about"><i class="g3i g3i-carret-bottom"></i></a>
              </div>
            </div>
          </div>
          <div>
        </div>
      </div>
      <svg viewBox="0 0 1920 47" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <g id="Plan-de-travail1" transform="matrix(1,0,0,1,0.1,-33.7869)">
            <path d="M0,33.787C424.352,80.771 614.774,74.172 729.06,74.172C843.345,74.172 1217.24,37.46 1469.94,40C1722.64,42.54 1920,57.399 1920,57.399L1920,80L0,80C0,80 -0.225,64.263 0,33.787Z"/>
        </g>
      </svg>
    </header>
    <div class="slogan">
      <h1>{{ slogan }}</h1>
      <!-- <p>On est au top de la forme gros!</p> -->
      <div class="social-media">
        <div v-for="sm in global.socialMedia" :key="sm.id">
          <a :href="sm.value.url" target="_blank"><i :class="'g3i g3i-' + sm.value.name"></i></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
export default {
    name: 'HeaderHome',
    data() {
      return {
        storageUrl: this.$store.state.storageUrl,
        videoObj: '',
      }
    },
    computed: {
      logo() {
        return this.$store.state.global['logo'].path
      },
      video(){
        return this.$store.state.global.videoHeader.path
      },
      slogan(){
        return this.$store.state.global.subHeaderTitle
      },
      ...mapState(['global'])
    },
    mounted () {
      document.querySelectorAll('.nav-btn').forEach(link => {
          link.addEventListener('click', function(e){
              let pos = document.querySelector(link.hash).offsetTop
              e.preventDefault()
              let currentPos = document.querySelector('html').scrollTop
              window.scrollBy({ 
                  top: pos-currentPos-54, // could be negative value
                  left: 0, 
                  behavior: 'smooth' 
              });
          })
      });
      var headerContent = document.querySelector('.header-content')
      function animeHeader(){
        headerContent.classList.toggle('show')
      }
      setInterval(animeHeader, 10000)
      var videoObj = document.querySelector('header .video video')
      function resizeVideoContainer(video){
        if (video != undefined) {
          if(video.parentNode.parentNode.clientWidth / video.parentNode.parentNode.clientHeight > 2.4){
            video.parentNode.style.height = window.innerWidth / 2.4 + 'px'
          } else {
            video.parentNode.style.height = '100%'
          }
        } 
      }
      resizeVideoContainer(videoObj);
      window.addEventListener('resize', function() {resizeVideoContainer(videoObj)}, false)
    },
}
</script>

<style lang="scss">
header{
  height: 85vh;
  width: 100%;
  background-position-y: center;
  position: relative;
  overflow: hidden;
}
header{
      svg{
          position: absolute;
          bottom: -1px;
          z-index: 4;
          height: auto;
      }
      svg, path{
          width: 100% !important;
          fill: $black-title;
      }
  
  .header-content{
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
    background-color: rgba(0,0,0,.2);
    transition: 2s;
    .container{
      display: flex;
      align-items: center;
      height: 100%;
      opacity: 0;
      transition: 1.5s;
      &:hover{
        opacity: 1;
      }
    }
    &.show{
      background-color: rgba(0,0,0,.7);
      
      .container{
        opacity: 1;
      }
    }
    &:hover{
        background-color:rgba(0,0,0,.6);
    }
  }
  i.g3i{
    font-size: 64px;
    margin-top: 90px;
    display: block;
    color: $black-content;
    opacity: .4;
    transition: .3s;
    @include mq576{
        font-size: 42px;
    }
    &:hover{
      opacity: .7;
      transform: scale(1.1);
    }
  }
}
.header-text-content{
  width: 100%;
  text-align: center;
  img{
    display: block;
    max-width: 500px;
    margin: 125px auto 30px auto;
    padding: 0 15px
  }
}
.slogan{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  padding: 25px 0 45px;
  background-color: $black-title;
  .social-media{
    display: flex;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    & > div{
      margin: 0 10px;
    }
    a, a i.g3i{
      margin-top: 0;
      font-size: 28px;
      transition: .3s;
      color: $grey-text;
      &:hover{
        color: $main-color;
      }
      @include mq576{
        font-size: 26px;
      }
    }
  }
  h1{
    color: $grey-text;
    //margin-bottom: 12px;
    font-family: $link-font;
    text-transform: uppercase;
    font-size: 26px;
    padding: 16px 15px;
    @include mq576{
      font-size: 18px;
    }
  }
  p{
    color: $grey-text;
    font-family: $link-font;
    font-size: 18px;
  }
}

.video{
  position: absolute;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  z-index: 1;
  margin: 0 auto;
  video {
    width: auto;
    height: 101%;
  }
}
</style>