<template>
    <section v-if="isLoaded.currentProject" :style="{backgroundImage: 'url(' + storageUrl + currentProject.backgroundImg.path + ')'}" id="current-project">

        <div v-if="isLoaded.currentProject" class="home-current-project">
            <div class="wrap1140">
                <div class="content">
                    <div>
                        <img :src="storageUrl + currentProject.img.path" alt="">
                    </div>
                    <div>
                        <h2 :style="{color: currentProject.colorProject}">{{ currentProject.title }}</h2>
                        <div v-html="currentProject.resume"></div>
                        <a class="btn" 
                            :href="currentProject.link"
                            :style="{backgroundColor: currentProject.colorProject}"
                        target="_blank" rel="noopener noreferrer">En savoir plus</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
      return {
        storageUrl: this.$store.state.storageUrl
      }
    },
    beforeMount () {
        this.getCurrentProject();
    },
    methods: {
        ...mapActions(['getCurrentProject']),
    },
    computed: {
        ...mapState(['currentProject', 'isLoaded']),
    }
}

</script>

<style lang="scss">
.home-current-project{
    padding: 50px 0 120px;
    .content{
        background-color: rgba($black-title, .9);
        padding: 60px 0;
        display: flex;
        border-radius: 5px;
        
        & > div{
            padding: 0 60px;
            &:nth-of-type(1){
                width: 25%;
                padding-right: 30px;
            }
            &:nth-of-type(2){
                width: 75%;
                padding-left: 30px;
                h2{
                    margin-bottom: 30px;
                }
                .btn{
                    margin-top: 30px;
                }
            }
        }
        @include mq1024{
                & > div{
                &:nth-of-type(1){
                    width: 33%;
                    padding-right: 15px;
                }
                &:nth-of-type(2){
                    width: 66%;
                    padding-left: 15px;
                }
            }
        }
        @include mq768{
            flex-direction: column;
            padding: 60px 15px 30px;
            & > div{
                &:nth-of-type(1){
                    width: 100%;
                    padding: 0 45px 60px;
                }
                &:nth-of-type(2){
                    width: 100%;
                    padding: 0 20px;
                }
            }

        }
    }
    @include mq1024{
        padding: 50px 15px 120px;
    }
}
</style>